import store from "@/store";

export default (to, from, next) => {
  const id = store.getters["services/current"];

  if (id) {
    store.dispatch("stages/unsetCompleted", ["varification", "pay"]);
    store.dispatch("form/reset");
    
    next();
  } else {
    next("/");
  }
};
