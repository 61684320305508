import config from "@/config";

export const logger = (initiator, ...args) => {
  if (config.debug) {
    console.log(
      `[${new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false
      })}]`,
      `${initiator.toUpperCase()}:`,
      ...args
    );
  }
};
