<template lang="pug">
  button.button(
    v-if='type === "button"'
    :class='classes ? classes.join(" ") : null'
    :area-label="label"
    type='button'
    @click='action'
  ) {{ label }}
  router-link.button(
    v-else-if='type === "link"'
    :class='classes ? classes.join(" ") : null'
    :to='to'
  ) {{ label }}
</template>

<script>
export default {
  props: {
    type: {
      default: "button",
      type: String
    },
    classes: Array,
    label: String,
    action: Function,
    to: String
  }
};
</script>

<style lang="scss">
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17.5px 16px;
  color: $white;
  background-color: $accent;
  border: none;
  border-radius: 14px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: ease background-color 300ms;

  &:hover {
    background-color: #36689c;
  }

  &.has-arrow {
    padding-top: 8px;
    padding-bottom: 8px;

    &:after {
      content: "";
      display: block;
      width: 34px;
      height: 34px;
      margin-left: 16px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      background-image: url(../img/arrow.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 10px 16px;
    }
  }

  &.has-area {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.is-large {
    padding-left: 46px;
    padding-right: 46px;
    font-size: 18px;

    &:after {
      width: 44px;
      height: 44px;
      background-size: 12px 20px;
    }
  }

  &.is-bordered {
    background-color: transparent;
    color: $accent;
    border: 1px solid $accent;
    transition: ease border-color 300ms, ease color 300ms;

    &:hover {
      color: $second;
      border-color: $second;
    }
  }

  &.is-wide {
    padding-left: 80px;
    padding-right: 80px;
  }

  &.is-upper {
    text-transform: uppercase;
  }
}
</style>
