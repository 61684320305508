export default {
  namespaced: true,
  getters: {
    inactive(state) {
      return state.inactive;
    },
  },
  mutations: {
    setActive(state)
    {
      state.inactive = false
    },
    setInactive(state)
    {
      state.inactive = true
    },
  },
  actions: {
    setInactive({ commit }) {
      commit("setInactive");
      //console.log("I'am inactive!")
    },
    setActive({ commit }) {
      commit("setActive");
      //console.log("I'am active!")
    },
  },
  state: {
    inactive: false,
  }
};
