//import issuers from "./_issuers";

export default {
  title: "Заявление об открытии лицевого счета",
  plugins: [
    {
      type: "bank",
      config: {
        bik: "bik",
        bank: "bank",
        bank_inn: "bank_inn",
        // bank_address: "bank_address",
        ks: "ks",
        // swift: "swift"
      }
    },
    {
      type: "kladr",
      config: {
        region: "region",
        city: "city_register",
        address: "address_register",
        zip: "index_register"
      }
    },
    {
      type: "kladr",
      config: {
        region: "region_fact",
        city: "city_fact",
        address: "address_fact",
        zip: "index_fact"
      }
    }
  ],
  activeStep: 0,
  data: {
    ticket: "",
    smsCode: "",
    issuer: {
      value: "",
      //options: issuers,
      allowOther: false
    },
    accountType: {
      value: "",
      options:
          "Лицевой счет владельца, Лицевой счет владельца для учета права ОДС, Лицевой счет доверительного управляющего",
      template: "radio",
      allowOther: false
    },
    account: "",
    name: "",
    surname: "",
    patronymic: "",
    passSeries: "",
    passNumber: "",
    passDate: "",
    birthdate: "",
    passScan: {
      value: [],
      type: "ImgField"
    },
    photo: {
      value: [],
      type: "ImgField"
    },
    additionalDocuments: {
      value: [],
      type: "ImgField"
    },
    notification: {
      template: "checkbox",
      value: false,
      type: "CheckboxField"
    },
    notificationType: {
      options:
          "Лично/уполномоченным представителем,Заказное письмо,Через личный кабинет акционера(при условии подачи документов через ЛКА)",
      value: "",
      allowOther: false,
      template: "radio"
    },
    docsByPost: {
      type: "CheckboxField",
      value: false,
      template: "checkbox"
    },
    docsByMail: {
      type: "CheckboxField",
      value: false,
      template: "checkbox"
    },
    citizenship: "Россия",
    birthplace: "",
    passIssuer: "",
    passCode: "",
    inn: {
      value: "",
      template: "split",
      length: 12
    },
    innFinder: {
      type: "InnButton"
    },
    snils: {
      value: "",
      template: "split",
      length: 11
    },
    address_fact: "",
    city_register: "",
    region_fact: "",
    region: "",
    city_fact: "",
    address_register: "",
    addresses_equal: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    index_fact: {
      value: "",
      template: "split",
      length: 6
    },
    index_register: {
      value: "",
      template: "split",
      length: 6
    },
    phone: {
      value: "",
      template: "split",
      length: 28
    },
    phone2: {
      value: "",
      template: "split",
      length: 28
    },
    email: {
      value: "",
      template: "split",
      length: "25"
    },
    notifyMeeting: {
      value: "",
      options: "Почтой,Электронной почтой,SMS сообщением",
      template: "radio"
    },
    bank: "",
    bank_address: "",
    rs: {
      value: "",
      template: "split",
      length: 20
    },
    ks: {
      value: "",
      template: "split",
      length: 20
    },
    ls: {
      value: "",
      template: "split",
      length: 22
    },
    bank_inn: {
      value: "",
      template: "split",
      length: 10
    },
    bik: {
      value: "",
      template: "split",
      length: 9
    },
    swift: "",
    bank_mediator: "",
    bank_mediator_account: "",
    info_reg: {
      value: "",
      options: "Осуществление операций в реестре",
      template: "radio",
      allowOther: true
    },
    info_source: {
      value: "",
      options:
          "Дарение ценных бумаг,Наследование ценных бумаг,Купля/продажа ценных бумаг",
      template: "radio",
      allowOther: true
    },
    foreign_duty: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    foreign_public_duty: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    russian_public_duty: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    director: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    another_winner: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    },
    another_benefit: {
      value: false,
      type: "CheckboxField",
      template: "checkbox"
    }
  },
  steps: [
    {
      index: 0,
      header: "1. Заявление об открытии лицевого счета",
      fields: [
        {
          id: "ticket",
          label: "Номер талона",
          legend:
              "Введите номер талона, который был выдан Вам на стойке регистрации",
          placeholder: "",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "accountType",
          label:
            "Прошу открыть лицевой счет в соответствии с анкетой зарегистрированного лица",
          placeholder: "",
          width: "full",
          legend: "Выбериет значение из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "notification",
          placeholder:
              "Прошу уведомить об открытии лицевого счета, услуга оплачивается в соответствии с тарифами/прейскурантом регистратора [*]Отправка документов заказным письмом осуществляется при условии оплаты почтовых услуг по Прейскуранту Регистратора[/*]",
          // mutateFields: [
          //   {
          //     id: "notificationType",
          //     value: false
          //   }
          // ]
        },
        {
          id: "notificationType",
          label:
              "Способ получения уведомления об открытии/об отказе в открытии счета",
          placeholder: "",
          width: "full",
          legend: "Выберите значение из выпадающего списка",
          visible: true,
          hiddenOptions: [
            "Через личный кабинет акционера(при условии подачи документов через ЛКА)"
          ],
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          text:
              "Документы, являющиеся основанием для совершения операций в реестре, могут быть предоставлены Регистратору:",
          type: "description",
          bold: true
        },
        {
          id: "docsByMail",
          placeholder:
              "Почтовым отправлением",
          width: "full",
          type: "CheckboxField"
        },
        {
          id: "docsByPost",
          placeholder:
              "Через личный кабинет акционера",
          width: "full",
          type: "CheckboxField"
        }
      ],
      description:
          "Анкета зарегистрированного лица предоставляется для открытия счета",
      valid: false
    },
    {
      index: 1,
      header: "2. Эмитент",
      fields: [
        {
          id: "issuer",
          label: "Полное официальное наименование эмитента ценных бумаг [*]Начните впечатывать название эмитента, после чего выберите его из выпадающей подсказки[/*]",
          placeholder: 'Публичное акционерное общество "Сбербанк"',
          width: "full",
          legend:
            "Начните вводить название эмитента, затем выберите подходящий вариант из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          endpoint: "/api/v1/issuers/search",
          showError: ""
        }
      ],
      valid: false
    },
    {
      index: 2,
      header: "3. Данные физического лица",
      fields: [
        {
          id: "surname",
          label: "Фамилия",
          placeholder: "Иванов",
          width: "one-half",
          legend: "Введите фамилию как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "name",
          label: "Имя",
          placeholder: "Иван",
          width: "one-half",
          legend: "Введите имя как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "patronymic",
          label: "Отчество",
          placeholder: "Иванович",
          width: "one-half",
          legend: "Введите отчество как в документе",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          showError: ""
        },
        {
          id: "citizenship",
          label: "Гражданство",
          placeholder: "",
          width: "one-half",
          legend: "Введите гражданство как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          }
        },
        {
          id: "birthdate",
          label: "Дата рождения",
          placeholder: "__.__.____",
          width: "one-half",
          mask: "d9.m9.y999",
          legend: "Введите дату рождения как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            }
          },
          showError: ""
        },
        {
          id: "birthplace",
          label:
              "Место рождения",
          width: "full",
          legend: "Введите место рождения как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "passSeries",
          label: "Серия паспорта",
          placeholder: "__ __",
          width: "one-half",
          mask: "99 99",
          legend: "Введите серию паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\s\\d{2}",
              error:
                "Серия паспорта состоит из двух чисел, разделенных пробелом (00 00)"
            }
          },
          showError: ""
        },
        {
          id: "passNumber",
          label: "Номер паспорта",
          mask: "999 999",
          placeholder: "___ ___",
          width: "one-half",
          legend: "Введите номер паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{3}\\s\\d{3}",
              error:
                "Номер паспорта состоит из двух чисел, разделенных пробелом (000 000)"
            }
          },
          showError: ""
        },
        {
          id: "passDate",
          label: "Дата выдачи паспорта",
          placeholder: "",
          width: "one-half",
          legend: "Введите дату выдачи паспорта",
          mask: "d9.m9.y999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\.\\d{2}\\.\\d{4}",
              error: "Дата должна быть введена в формате дд.мм.гггг"
            },
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            },
            custom: ["date"]
          },
          showError: ""
        },
        {
          id: "passIssuer",
          label:
              "Наименование органа, выдавшего документ[*]Наименование органа укажите точно как в паспорте[/*]",
          placeholder: "",
          legend:
              "Введите наименование органа, выдавшего паспорт как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "passCode",
          label: "Код подразделения",
          placeholder: "",
          width: "one-half",
          legend: "Введите код подразделения в точности, как в паспорте",
          mask: "999-999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{3}\\-\\d{3}",
              error:
                "Код подразделения состоит из двух чисел, разделенных дефисом (000-000)"
            }
          },
          showError: ""
        },
        {
          id: "snils",
          label: "СНИЛС",
          width: "one-half",
          mask: "999-999-999 99",
          legend: "Укажите номер снилс в точности, как в документе",
          validate: {
            pattern: {
              reg: "^(\\d{3}\\-\\d{3}-\\d{3}\\s\\d{2})?$",
              error: "Укажите номер снилс в заданном формате (000-000-000 00)"
            }
          }
        },
        {
          id: "inn",
          label:
            "ИНН [*]ИНН - индивидуальный номер налогоплательщика. Если у Вас есть ИНН, но Вы не знаете его номер, нажмите на кнопку справа, чтобы автоматически внести его в поле[/*]",
          width: "one-half",
          mask: "999999999999",
          legend: "",
          validate: {
            pattern: {
              reg: "^(\\d{12})?$",
              error: "Укажите ИНН в заданном формате (12 цифр, без пробелов)"
            }
          }
        },
        {
          id: "innFinder",
          fields: {
            surname: "surname",
            patronymic: "patronymic",
            name: "name",
            birthdate: "birthdate",
            birthplace: "birthplace",
            pass_serial: "passSeries",
            pass_number: "passNumber",
            pass_date: "passDate"
          },
          field: "inn",
          width: "one-half",
          text: "Узнать свой ИНН"
        }
      ],
      valid: false
    },
    {
      index: 3,
      header: "4. Адресные данные",
      fields: [
        {
          id: "region",
          label:
            "Область [*]Начните впечатывать название субъекта РФ, после чего выберите его из выпадающей подсказки[/*]",
          legend:
            "Заполнять это поле НЕ НУЖНО, если Вы проживаете на территории следующих субъектов РФ: Москва, Санкт-Петербург, Севастополь, а также на территории города федерального значения Байконур",
          visible: true
        },
        {
          id: "city_register",
          label: "Город места регистрации",
          legend:
              "Начните впечатывать название города, после чего выберите его из выпадающей подсказки",
          visible: true,
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "address_register",
          label:
              "Адрес места регистрации (улица, дом, корпус, квартира) [*]Начните вводить название улицы, после чего выберите ее из подсказки, введите оставшуюся часть адреса[/*]",
          width: "full",
          visible: true,
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "index_register",
          label: "Индекс места регистрации",
          placeholder: "______",
          width: "one-half",
          legend: "Введите индекс места регистрации",
          mask: "999999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "addresses_equal",
          placeholder: "Адрес проживания совпадает с адресом регистрации",
          mutateFields: [
            {
              id: "region_fact",
              value: true
            },
            {
              id: "index_fact",
              value: true
            },
            {
              id: "address_fact",
              value: true
            },
            {
              id: "city_fact",
              value: true
            }
          ]
        },
        {
          id: "region_fact",
          label:
            "Область [*]Начните впечатывать название субъекта РФ, после чего выберите его из выпадающей подсказки[/*]",
          legend:
            "Заполнять это поле НЕ НУЖНО, если Вы проживаете на территории следующих субъектов РФ: Москва, Санкт-Петербург, Севастополь, а также на территории города федерального значения Байконур",
          visible: true,
          showError: ""
        },
        {
          id: "city_fact",
          label: "Город места проживания",
          legend:
              "Начните впечатывать название города, после чего выберите его из выпадающей подсказки",
          visible: true,
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "address_fact",
          label: "Адрес места проживания",
          placeholder: "",
          width: "full",
          legend: "Введите адрес фактического места проживания",
          visible: true
        },
        {
          id: "index_fact",
          label: "Индекс места проживания",
          placeholder: "______",
          width: "one-half",
          legend: "Введите индекс места проживания",
          mask: "999999",
          visible: true
        }
      ],
      valid: false
    },
    {
      index: 4,
      header: "5. Контактные данные",
      fields: [
        {
          id: "email",
          label: "Адрес электронной почты",
          showError: "",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          width: "full",
          placeholder: "example@email.ru",
          legend: "Введите адрес электронной почты"
        },
        {
          id: "phone",
          label: "Номер телефона",
          showError: "",
          validate: {
            // required: {
              // error: "Данное поле обязательно для заполнения"
            // },
            pattern: {
              reg: "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$",
              error: "Некорректно введен номер телефона",
            }
          },
          width: "full",
          placeholder: "+7 (___) ___-__-__",
          legend: "Введите номер телефона (без 8)",
          mask: "+7 (999) 999-99-99"
        },
        {
          id: "phone2",
          label: "Дополнительный номер телефона",
          showError: "",
          validate: {
            // required: {
              // error: "Данное поле обязательно для заполнения"
            // },
            pattern: {
              reg: "^((8|\\+7)[\\- ]?)?(\\(?\\d{3}\\)?[\\- ]?)?[\\d\\- ]{7,10}$",
              error: "Некорректно введен номер телефона",
            }
          },
          width: "full",
          placeholder: "+7 (___) ___-__-__",
          legend: "Введите номер телефона (без 8)",
          mask: "+7 (999) 999-99-99"
        },
        {
          id: "notifyMeeting",
          label:
              "Сообщение о проведении собрания направлять[*]Указывается в случае наличия указанной возможности в Уставе эмитента[/*]",
          legend:
              "Выберите вариант ответа из выпадающего списка, либо впечатайте в данное поле свой вариант",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        }
      ],
      valid: false
    },
    {
      index: 5,
      header:
          "6. Сведения о реквизитах для выплаты доходов по ценным бумагам, причитающихся физическому лицу",
      description:
          "Способ выплаты доходов по ценным бумагам: перечисление на банковский счет (в случае отсутствия реквизитов для выплаты доходов по ценным бумагам выплата доходов осуществляется почтовым переводом по адресу фактического места жительства).",
      valid: false,
      fields: [
        {
          id: "bik",
          label:
            "БИК[*]Введите БИК Вашего банка, доступыне данные о банке автоматически загрузятся в соответствующие поля[/*]",
          width: "one-half",
          mask: "999999999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "^(\\d{9})?$",
              error: "Укажите БИК в заданном формате (9 цифр, без пробелов)"
            }
          }
        },
        {
          id: "bank_inn",
          label: "ИНН банка",
          width: "one-half",
          mask: "9999999999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "^(\\d{10})?$",
              error: "Укажите ИНН в заданном формате (10 цифр, без пробелов)"
            }
          },
          showError: ""
        },
        {
          id: "bank",
          label: "Банк получателя/Отделение (наименование), город",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "bank_address",
          label: "Местонахождение банка (для иностранных банков)",
          width: "full"
        },
        {
          id: "ls",
          label: "Лицевой счет",
          mask: "9999 9999 9999 9999 9999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "^(\\d{4}\\s?){5}$",
              error: "Счет должен состять из 20 чисел"
            }
          },
          showError: ""
        },
        {
          id: "ks",
          label: "Корреспондентский счет",
          mask: "9999 9999 9999 9999 9999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "^(\\d{4}\\s?){5}$",
              error: "Счет должен состять из 20 чисел"
            }
          },
          showError: ""
        },
        {
          id: "swift",
          label: "SWIFT",
          width: "full"
        },
        {
          id: "bank_mediator",
          label: "Наименование банка посредника"
        },
        {
          id: "bank_mediator_account",
          label: "Номер счета банка получателя в банке посреднике"
        }
      ]
    },
    {
      index: 6,
      header:
          "7. Информация о целях установления деловых отношений с организацией и предполагаемый характер",
      valid: false,
      fields: [
        {
          id: "info_reg",
          label:
              "Информация о целях установления деловых отношений с организацией и предполагаемый характер",
          legend:
              "Выберите вариант ответа из выпадающего списка, либо впечатайте в данное поле свой вариант",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "info_source",
          label: "Источники происхождения ценных бумаг",
          legend:
              "Выберите вариант ответа из выпадающего списка, либо впечатайте в данное поле свой вариант",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        }
      ]
    },
    {
      index: 7,
      header: "8. Принадлежность к категории должностных лиц",
      description:
        "В случае положительного ответа в одном из пунктов, обязательно заполнение Опросного листа",
      fields: [
        {
          id: "foreign_duty",
          placeholder:
            "Подтверждаю, что являюсь иностранным публичным должностным лицом / супругой (-ом), близким родственником [*]ИПДЛ - любое назначаемое или избираемое физическое лицо, занимающее какую-либо должность в законодательном, исполнительном, административном или судебном органе иностранного государства, и любое лицо, выполняющее какую-либо публичную функцию для иностранного государства, в том числе для публичного[/*]",
          type: "CheckboxField"
        },
        {
          id: "foreign_public_duty",
          placeholder:
            "Подтверждаю, что являюсь должностным лицом публичной международной организации [*]ДЛПМО - международный гражданский служащий или любое физическое должностное лицо, выполняющее какую-либо публичную функцию для иностранного публичного ведомства или государственного предприятия от их имени[/*]",
          type: "CheckboxField"
        },
        {
          id: "russian_public_duty",
          placeholder:
            "Подтверждаю, что являюсь российским публичным должностным лицом [*]РПДЛ - физическое лицо, замещающее (занимающее) государственную должность Российской Федерации, должность члена Совета директоров Центрального банка Российской Федерации, должность федеральной государственной службы, назначение на которые и освобождение от которых осуществляется Президентом Российской Федерации или Правительством Российской Федерации, должность в Центральном банке Российской Федерации, государственной корпорации и иной организации, созданной Российской Федерацией на основании федеральных законов, включенной в перечень должностей, определяемый Президентом Российской Федерации[/*]",
          type: "CheckboxField"
        },
        {
          id: "director",
          placeholder:
            "Подтверждаю, что являюсь руководителем или учредителем некоммерческой организации, иностранной некоммерческой неправительственной организации, ее отделения, филиала или представительства, осуществляющих свою деятельность на территории Российской Федерации",
          type: "CheckboxField"
        },
        {
          id: "another_winner",
          placeholder:
            "Подтверждаю, что Выгодоприобретателем является иное лицо [*](Выгодоприобретатель - лицо, к выгоде которого действует клиент, в том числе на основании агентского договора, договоров поручения, комиссии и доверительного управления, при проведении операций с денежными средствами и иным имуществом)[/*]",
          type: "CheckboxField"
        },
        {
          id: "another_benefit",
          placeholder:
            "Подтверждаю, что Бенефициарным владельцем является иное лицо [*]Бенефициарным владельцем клиента - физического лица считается это лицо, за исключением случаев, если имеются основания полагать, что бенефициарным владельцем является иное физическое лицо[/*]",
          type: "CheckboxField"
        }
      ]
    }
  ]
};
