<template lang="pug">
  section.content(
    :class='styles'
  )
    header.content__header(
      v-if='title && !isSuccessStage'
    )
      h1.content__title(v-if="currentQRCode===false") Завершение операции
    h1.content__title(v-else) {{title}}

    .content__wrap
      .content__body
        header.content__header(
          v-if='isSuccessStage'
        )
          h1.content__title Поздравляем!<br>Вы успешно заполнили форму
        slot(name='body')

      .content__sidebar
        slot(name='sidebar')

</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: String
  },
  computed: {
    ...mapGetters({
      currentStage: "stages/current",
      currentQRCode: "document/getQrcodePath"
    }),
    isSuccessStage() {
      return this.currentStage === "success";
    },
    isServicesStage() {
      return this.currentStage === "services";
    },
    styles() {
      return {
        "is-success": this.isSuccessStage,
        "is-services": this.isServicesStage
      };
    }
  }
};
</script>

<style lang="scss">
.content {
  $this: &;

  &.is-services {
    #{$this}__sidebar {
      margin-top: 12px;
    }
  }

  &.is-success {
    #{$this}__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 25px 0;
      text-align: center;

      &::before {
        content: "";
        display: block;
        width: 48px;
        height: 35px;
        margin-bottom: 18px;
        background-image: url(../img/success.svg);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
      }
    }

    #{$this}__sidebar {
      margin-top: 25px;
    }
  }

  &__header {
    width: 100%;
  }

  &__title {
    color: $white;
    font-weight: $regular;
    line-height: 1.2;
  }

  &__wrap {
    display: flex;
  }

  &__header + &__wrap {
    display: flex;
    margin-top: 20px;
  }

  &__body {
    min-width: 596px;
  }

  &__sidebar {
    width: 288px;
    margin-left: 20px;
    flex-shrink: 0;
  }
}

.content__body.content__body-auto {
  min-width: auto !important;

  .box {
    width: 100%;
    min-height: auto;

    .payment__topspace {
      margin-top: 20px;
    }

    .payment__footer {
      margin-top: 30px;
    }
  }
}
</style>
