<template lang="pug">
  .app(
    :class='appClass'
    ref='app'
  )
    .app__header
      Header
    .app__content
      Content(
        :title='$route.meta.title'
      )
        template(slot='body')
          router-view
        template(slot='sidebar')
          Stages

    .app__progress(
      :style='progressStyle'
    )
    InactivityAlert(
      v-if='inactive'
    )
    .app__modal(
      v-show='modal'
    )
      Modal(
        :content='modal'
      )
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Header from "@/components/Header.vue";
import Stages from "@/components/Stages.vue";
import Content from "@/components/Content.vue";
import Modal from "@/components/Modal.vue";

import InactivityAlert from "@/components/InactivityAlert.vue";

export default {
  name: "StatusApp",
  data: () => ({
    loading: true
  }),
  computed: {
    ...mapGetters({
      progress: "stages/progress",
      modal: "modal/content",
      inactive: "user/inactive"
    }),
    appClass() {
      return {
        "has-modal": this.modal,
        "is-loading": this.loading
      };
    },
    progressStyle() {
      return { width: `${this.progress}%` };
    }
  },
  watch: {
    modal() {
      if (this.modal) {
        this.hideScrollbar();
      } else {
        this.showScrollbar();
      }
    }
  },
  components: {
    Header,
    Stages,
    Content,
    Modal,
    InactivityAlert,
  },
  methods: {
    ...mapActions({
      setModal: "modal/set",
      setActive: "user/setActive",
      setServices: "services/setState"
    }),
    hideScrollbar() {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      const app = this.$refs.app;

      app.style.paddingRight = `${scrollbarWidth}px`;
      app.style.overflow = "hidden";
      app.style.height = "100vh";
    },
    showScrollbar() {
      const app = this.$refs.app;

      app.style.paddingRight = null;
      app.style.overflow = null;
      app.style.height = null;
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 200);
  }
};
</script>

<style lang="scss">
.app {
  $this: &;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: $maxwidth + $gap * 2;
  max-width: 1980px;
  margin: auto;
  position: relative;
  background-color: $darkblue;
  background-image: $gradient;
  background-image: url("../img/emblem.png"), $gradient;
  background-repeat: no-repeat;
  background-position: 50% 0;
  color: $white;

  &.is-loading {
    opacity: 0;
  }

  &.has-modal {
    #{$this}__header,
    #{$this}__content,
    #{$this}__progress {
      filter: blur(6px);
    }
  }

  &__header {
    flex-shrink: 0;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin-top: 20px;
  }

  &__progress {
    margin-top: 20px;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    height: 6px;
    width: 0%;
    background-color: $second;
    border-radius: 0 3px 0 0;
    pointer-events: none;
    transition: ease-out width 600ms 100ms;
    z-index: 1;
  }
}
</style>
