import newAccount from "@/forms/new-account";
import changeAccount from "@/forms/change-account";
import info from "@/forms/info";
import sellStocks from "@/forms/sell-stocks";
import sellStocksDeposit from "@/forms/sell-stocks-deposit";

export default {
  services: {
    categories: [
      {
        id: 1,
        title: "Операции со счетами и запрос информации из реестра",
        list: [1, 3, 5]
      },
      {
        id: 2,
        title: "Операции с ценными бумагами",
        list: [6, 9, 8]
      }
    ],
    items: [
      {
        id: 1,
        image: "/img/service-1.jpg",
        title: "Заявление об открытии лицевого счета",
        name: "Открытие лицевого счета для физического лица",
        caption: "Счет дает право участвовать в сервисе",
        price: 135,
        form: newAccount
      },
      {
        id: 3,
        image: "/img/service-2.jpg",
        title: "Заявление о внесении изменений в реквизиты",
        name: "Внести изменения в реквизиты",
        caption: "Услуга по внесению изменений предоставляется бесплатно",
        form: changeAccount
      },
      {
        id: 5,
        image: "/img/service-3.jpg",
        title: "Заявление о запросе информации",
        name: "Запросить информацию",
        caption: "Запросить выписку, справку, уведомление",
        price: 270,
        form: info
      },
      {
        id: 6,
        image: "/img/service-4.jpg",
        title: "Заявление о cписании ценных бумаг",
        name: "Списание ценных бумаг",
        caption: "Услуга по списанию ценных бумаг",
        price: 150,
        form: sellStocks
      },
      {
        id: 9,
        image: "/img/service-5.jpg",
        title: "Заявление о зачислении ЦБ с участием центрального депозитария",
        name: "Зачисление ЦБ с участием центрального депозитария",
        caption: "Услуга по зачислению ЦБ",
        // price: 300,
        form: sellStocksDeposit
      },
      {
        id: 8,
        image: "/img/service-6.jpg",
        title: "Заявление о списании ЦБ с участием центрального депозитария",
        name: "Списание ЦБ с участием центрального депозитария",
        caption: "Услуга по списанию ЦБ",
        // price: 300,
        form: sellStocksDeposit
      }
    ]
  }
};
