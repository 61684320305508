export default {
    namespaced: true,
    state: {
        id: "",
        remotePath: "",
        localPath: "",
        paymentPath: "",
        qrcodePath: "",
    },
    mutations: {
        setId(state, id) {
            console.log(id);
            state.id = id;
        },
        setRemotePath(state, path) {
            state.localPath = path;
        },
        setPaymentPath(state, path) {
            state.paymentPath = path;
        },
        setQrcodePath(state, path) {
            state.qrcodePath = path;
        }
    },
    getters: {
        getId: state => state.id,
        getPaymentPath: state => state.paymentPath,
        getQrcodePath: state => state.qrcodePath,
    }
}