<template lang="pug">
  .stage-progress
    .stage-progress__count(
      v-if='progress < 91'
      :style='{ left: `${progress}%`}'
    ) {{ progress }}%
    .stage-progress__line
      .stage-progress__value(
        :style='{ transform: `scaleX(${progress / 100})`}'
      )
</template>

<script>
export default {
  props: {
    progress: Number
  }
};
</script>

<style lang="scss">
.stage-progress {
  padding-top: 14px;
  position: relative;

  &__count {
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    font-weight: $medium;
    font-size: 12px;
    line-height: 1;
    transform: translateX(-50%);
    transition: ease left 350ms;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: rgba(59, 123, 190, 0.5);
    border-radius: 14px;
  }

  &__value {
    width: 100%;
    height: 100%;
    background-color: $second;
    transform: scaleX(0);
    transform-origin: left;
    transition: ease transform 350ms;
  }
}
</style>
