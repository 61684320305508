import $ from "jquery";
import { logger } from "@/vendor/logger";
import { debounce } from "@/vendor/debounce";
class Counter {
  constructor(callback, duration = 5000) {
    this.duration = duration;
    this.callback = callback;
    this.timer = null;
    this.active = false;
    this.debouncedReset = debounce(this.reset.bind(this), 500);
    logger("Counter", "Created");
  }

  set() {
    if (this.active) {
      this.timer = setTimeout(() => {
        logger("Counter", "Callback");
        this.callback();
      }, this.duration);
    } else {
      this.clear();
    }
  }

  clear() {
    clearTimeout(this.timer);
    this.timer = null;
  }

  reset() {
    if (this.active) {
      this.clear();
      setTimeout(() => {
        this.set();
      });
      logger("Counter", "Reset");
    }
  }

  start() {
    if (!this.active) {
      this.active = true;
      this.set();
      logger("Counter", "Start");
      $(document).on(
        "click blur focus mousemove keypress keyup keydown scroll",
        this.debouncedReset
      );
    } else {
      this.reset();
    }
  }

  stop() {
    if (this.active) {
      this.active = false;
      this.clear();
      logger("Counter", "Stopped");
      $(document).off(
        "click blur focus mousemove keypress keyup keydown scroll",
        this.debouncedReset
      );
    }
  }
}

export default Counter;
