export default {
  namespaced: true,
  getters: {
    content(state) {
      return state.content;
    }
  },
  mutations: {
    set(state, content) {
      state.content = content;
    }
  },
  actions: {
    set({ commit }, content) {
      commit("set", content);
    }
  },
  state: {
    content: null
  }
};
