import store from "@/store";

export default (to, from, next) => {
  const id = store.getters["services/current"];
  const formStageCompleted = store.getters["stages/isCompleted"]("form");
  const varificationStageCompleted = store.getters["stages/isCompleted"](
    "varification"
  );
  const payStageCompleted = store.getters["stages/isCompleted"]("pay");

  if (
    id &&
    formStageCompleted &&
    varificationStageCompleted &&
    payStageCompleted
  ) {
    store.dispatch("stages/unsetCompleted", ["form", "varification", "pay"]);

    next();
  } else {
    next("/");
  }
};
