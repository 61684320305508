import data from "@/data.js";
import axios from "axios";
import { errorAlert } from "@/vendor/alerts";

const getInitialServices = () => {
  return JSON.parse(JSON.stringify(data.services));
};

const API_SERVICES_BASE = `${process.env.VUE_APP_API_SERVER}/api/v1/services/templates/`;

const getAllServicesData = async () => {
  const API_SERVICES_ENDPOINT = API_SERVICES_BASE;
  return await axios.get(API_SERVICES_ENDPOINT);
};

const getSingleServiceData = async (serviceSlug) => {
  const endpointURL = API_SERVICES_BASE+serviceSlug;
  return await axios.get(endpointURL);
};

const convertServiceListToLocalFormat = (servicesData) => {
  let servicesArray = [];
  for(const serviceId in servicesData){
    const serviceDataObject = servicesData[serviceId];
    servicesArray.push({
      id: serviceId,
      image: getLocalImageURLForService(parseInt(serviceId)),
      price: getPriceForService(parseInt(serviceId)),
      ...serviceDataObject
    });
  }
  return servicesArray;
}

const TICKET_FIELD_DATA = {
  id: "ticket",
  label: "Номер талона",
  legend:
      "Введите номер талона, который был выдан Вам на стойке регистрации",
  placeholder: "",
  validate: {
    required: {
      error: "Данное поле обязательно для заполнения"
    }
  },
  showError: false
}

const reindexStages = (formData) => {
  let newData = formData;

  for(let stepIndex = 0; stepIndex < newData.steps.length; stepIndex++){
    if(newData.steps[stepIndex].index !== stepIndex){
      let old_index = newData.steps[stepIndex].index;
      newData.steps[stepIndex].index = stepIndex;

      formData.steps.forEach((step) => {
        step.fields.forEach((field) => {
          if(field.hasOwnProperty("mutateStages") && field.mutateStages){
            for(let i = 0; i < field.mutateStages.length; ++i){
              if(field.mutateStages[i].index === old_index){
                field.mutateStages[i].index = stepIndex;
              }
            }
          } 
        })
      })
    }
  }
  
  return newData;
}

const getOldLocalServiceDataPath = (serviceId) => {
  switch(serviceId){
    case 1:
       return "@/forms/new-account.js";
    case 3:
      return "@/forms/change-account.js";
    case 5:
        return "@/forms/info.js";
    case 6:
        return "@/forms/sell-stocks.js";
    case 8:
    case 9:
        return "@/forms/sell-stocks-deposit.js";
  }
  return null;
}

const convertServiceFormToLocalFormat = async (formData, serviceId) => {
  let newData = formData;

  //у нас шаги это steps, а не stages, надо изменить имя свойства
  Object.defineProperty(newData, "steps",
    Object.getOwnPropertyDescriptor(formData, "stages"));
  delete newData["stages"];

  //теперь добавляем первое поле - номер талона
  let firstStep = newData.steps[0];
  firstStep.fields.unshift(TICKET_FIELD_DATA);
  newData.data["ticket"] = "";

  newData = fixUserIssuersFieldsForAnonymous(newData);
  newData = removeLKADataGettersFromFieldsData(newData);
  newData = removeFileFieldsFromStages(newData);
  newData = removeAnketaStage(newData);
  newData = reindexStages(newData);

  // let oldJSONData = null;
  // switch(parseInt(serviceId)){
  //   case 1: 
  //     oldJSONData = await import("@/forms/new-account.js");
  //   case 3:
  //     oldJSONData = await import("@/forms/change-account.js");
  //   case 5:
  //       oldJSONData = await import("@/forms/info.js");
  //   case 6:
  //       oldJSONData = await import("@/forms/sell-stocks.js");
  //   case 8:
  //   case 9:
  //       oldJSONData = await import("@/forms/sell-stocks-deposit.js");
  // }

  // if(oldJSONData){
  //   let plugins = oldJSONData.default.plugins;
  //   newData.plugins = plugins;
  // }
  
  return newData;
}

const fixUserIssuersFieldsForAnonymous = (formData) => {
  for(let stage of formData.steps){
    for(let field of stage.fields){
      if(field.id == "issuer" && field.search === false){
        field.search = true;
        field.endpoint =  "/api/v1/issuers/search";
      }
    }
  }
  return formData;
};

const removeLKADataGettersFromFieldsData = (formData) => {

  const PERCENTAGE_CODE_REGEXP = /^%.*%$/;

  for(const fieldname in formData.data){
    if(typeof formData.data[fieldname] == "string" && PERCENTAGE_CODE_REGEXP.test(formData.data[fieldname])){
      formData.data[fieldname] = "";
    }
    if(typeof formData.data[fieldname] == "object"){
      if((formData.data[fieldname].hasOwnProperty("options") && PERCENTAGE_CODE_REGEXP.test(formData.data[fieldname].options)) || 
      (formData.data[fieldname].hasOwnProperty("value") && PERCENTAGE_CODE_REGEXP.test(formData.data[fieldname].value))){
        if(formData.data[fieldname].hasOwnProperty("options")){
          formData.data[fieldname].options = "";
        }
        if(formData.data[fieldname].hasOwnProperty("value")){
          formData.data[fieldname].value = "";
        }
      }
    }
  }

  return formData;
}

const getFieldData = (field, formData) => {
  let fieldID = field.id;

  if(formData.data.hasOwnProperty(fieldID)){
    return formData.data[fieldID];
  }
  else{
    return null;
  }
}

const removeFileFieldsFromStages = (formData) => {
  for(let stepIndex = 0; stepIndex < formData.steps.length; ){
    let step = formData.steps[stepIndex];
    for(let i = 0; i < step.fields.length; ){
      let field = step.fields[i];
      let field_data = getFieldData(field, formData);
      if(field_data && typeof field_data === "object" && field_data.hasOwnProperty("type") && field_data.type == "ImgField"){
        delete formData.data[field.id];
        step.fields.splice(i, 1);
      }
      else{
        i++;
      }
    }
    //удалили все поля!
    if(!step.fields.length){
      formData.steps.splice(stepIndex, 1);
    }
    else{
      stepIndex++;
    }
  }
  return formData;
}

const getLocalImageURLForService = serviceID => {
  switch (serviceID) {
    case 1:
      return "/img/service-1.jpg";
    case 3:
      return "/img/service-2.jpg";
    case 5:
      return "/img/service-3.jpg";
    case 6:
      return "/img/service-4.jpg";
    case 9:
      return "/img/service-5.jpg";
    case 8:
      return "/img/service-6.jpg";
    default:
      return "/img/service-1.jpg";
  }
};

const getPriceForService = serviceID => {
  switch (serviceID) {
    case 1:
      return 135;
    case 3:
      return 0;
    case 5:
      return 270;
    case 6:
      return 150;
    case 9:
      return 300;
    case 8:
      return 300;
    default:
      return 0;
  }
};

const isAnketaStep = step => {
  let headerRegexp = /(.+)?\s+Подписание анкеты/;
  let hasContractLink = step.fields.some( field => field.id === "linkContract");
  let headerMatch = headerRegexp.test(step.header);
  return hasContractLink && headerMatch;
};

const removeAnketaStage = formData => {
  for (let stepIndex = 0; stepIndex < formData.steps.length; ) {
    let step = formData.steps[stepIndex];
    if(isAnketaStep(step)){
      formData.steps.splice(stepIndex, 1);
    } else {
      stepIndex++;
    }
  }
  return formData;
};

const findSlugForServiceById = (items, id) => {
  let item = items.find((serviceItem) => Number(serviceItem.id) == Number(id));
  if(item){
    return item.slug;
  }
  else{
    return null;
  }
}

const handleAPIResponse = (response) => {
  if(response.status == 200){
    return response.data;
  }
  else{
    return null;
  }
}

export default {
  namespaced: true,
  getters: {
    categories(state) {
      return state.categories;
    },
    items(state) {
      return state.items;
    },
    item(state) {
      return id => {
        return state.items.find(item => Number(item.id) === Number(id)) || null;
      };
    },
    current(state) {
      return state.current;
    }
  },
  mutations: {
    setCurrent(state, id) {
      state.current = id;
    },
    setItems(state, items) {
      state.items = [...items];
    },
    setCategories(state, categories) {
      state.categories = [...categories];
    },
    setItemForm(state, formData){
      let itemIndex = state.items.findIndex(item => Number(item.id) === Number(state.current));
      state.items[itemIndex].form = formData;
    },
  },
  actions: {
    async setState({ commit }) {
      //пока берем категории из старого JSON, остальное по запросу со Статус-Онлайн
      const { categories, items } = getInitialServices();

      let servicesDataResponse = await getAllServicesData();
      let servicesData = handleAPIResponse(servicesDataResponse);
      if(servicesData){
        servicesData = convertServiceListToLocalFormat(servicesData);
        commit("setCategories", categories);
        commit("setItems", servicesData);
      }
      else{
        errorAlert("Ошибка при загрузке списка услуг с сервера");
      }
    },
    async setCurrent({ commit, getters, dispatch }, id) {
      commit("setCurrent", id);
      localStorage.setItem("service", id);
      if(getters.item(id)){
       if(!getters.item(id).hasOwnProperty("form")){
        await dispatch('loadFormForItem', id);
       }
      } 
    },
    async loadFormForItem({commit, getters}, id){
      let serviceSlug = findSlugForServiceById(getters.items, id);
      if(serviceSlug){
        let serviceFormResponse = await getSingleServiceData(serviceSlug);
        let serviceFormTemplate = handleAPIResponse(serviceFormResponse);
        if(serviceFormTemplate){
          commit("setItemForm", await convertServiceFormToLocalFormat(serviceFormTemplate, id));
          console.log("form loaded via AJAX");
        }
        else{
          errorAlert("Не удалось загрузить форму услуги с сервера");
        }
      }
    },
    removeCurrent({ commit }) {
      commit("setCurrent", null);
      localStorage.removeItem("service");
    }
  },
  state: {
    categories: [],
    items: [],
    current: localStorage.getItem("service") || null
  }
};
