<template functional>
  <svg
    class="icon"
    v-if="$options.getIcon(`${props.name}Icon`)"
    :style="{
      width: props.width ? `${props.width}px` : null,
      height: props.height ? `${props.height}px` : null
    }"
  >
    <use :xlink:href="`#${$options.getIcon(`${props.name}Icon`).id}`"></use>
  </svg>
</template>

<script>
// import carsIcon from "@/assets/icons/cars.svg";

const lib = {
  // agroIcon,
};

export default {
  name: "Icon",
  getIcon(name) {
    return lib[name];
  }
};
</script>

<style lang="scss">
.icon {
  display: block;
  width: 100%;
  height: 100%;
  color: currentColor;
}
</style>
