import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import formPolice from "@/policies/form";
import varificationPolice from "@/policies/varification";
import payPolice from "@/policies/pay";
import successPolice from "@/policies/success";

import Counter from "@/counter";
import config from "@/config.js";

import { logger } from "@/vendor/logger";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "service",
      path: "/service/:id",
      beforeEnter: async (to) =>  {
        const id = to.params.id;

        if (id) {
          await store.dispatch("services/setCurrent", id);
          router.push("/form");
        } else {
          router.push("/");
        }
      }
    },
    {
      name: "form",
      path: "/form",
      meta: { title: "Заявление" },
      beforeEnter: formPolice,
      component: () => import("@/pages/Form.vue")
    },
    {
      name: "varification",
      path: "/varification",
      meta: { title: "Проверьте правильность введенных данных" },
      beforeEnter: varificationPolice,
      component: () => import("@/pages/Varification.vue")
    },
    {
      name: "pay",
      path: "/pay",
      meta: { title: "Оплата услуги" },
      beforeEnter: payPolice,
      component: () => import("@/pages/Pay.vue")
    },
    {
      name: "success",
      path: "/success",
      beforeEnter: successPolice,
      meta: { title: "Поздравляем! Вы успешно заполнили форму" },
      component: () => import("@/pages/Success.vue")
    },
    {
      name: "services",
      path: "/",
      meta: { title: "Пожалуйста, выберите услугу" },
      component: () => import("@/pages/Services.vue")
    },
    {
      name: "other",
      path: "*",
      redirect: "/"
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

const counter = new Counter(() => {
  store.dispatch("user/setInactive");
}, config.inactivityDuration);

router.afterEach(to => {
  logger("Router", "After transition", to);

  switch (to.name) {
    case "form":
    case "varification":
    case "pay":
    case "success":
      counter.start();
      break;
    case "services":
      store.dispatch("services/setState");
      counter.stop();
      break;
    default:
      counter.stop();
      break;
  }
});

export default router;
