<template>
<!-- START: MODAL -->
<div v-if="open" :class="'modal modal--open' + ' modal--' + size">
  <div class="modal__overlay">  
    <div class="modal__box">
      <div class="modal__content">
        <slot></slot>
      </div>
    </div>
  </div>
</div>
<!-- START: MODAL -->
</template>

<script>

export default {
  name: "DynamicModal",
  props: {
    title: {
      type: String
    },
    open: {
      type: Boolean,
      default: false
    },
    onConfirm: {
      type: Function,
      default: function () {}
    },
    onCancel: {
      type: Function,
      default: function () {}
    },
    size: {
      type: String,
      default: 'xl'
    }
  }
}

</script>

<style lang="css">
  .modal--xs .modal__content {
    max-width: 620px;
    border-radius: 14px;
    padding: 35px;
  }
</style>
