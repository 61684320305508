<template lang="pug">
  .stages
    ul.stages__list
      li.stages__item(
        v-for='item in list'
        v-if='item.name !== "success"'
        :key='item.label'
        :class='item.name === current ? "is-current" : null'
      ) 
        .stages__point
        .stages__headline
          .stages__label {{ item.label }}
          .stages__progress(
            v-if='item.name ==="form" && current ==="form"'
          )
            StageProgress(
              :progress='formProgress'
            )
          
</template>

<script>
import { mapGetters } from "vuex";

import StageProgress from "@/components/StageProgress.vue";

export default {
  computed: {
    ...mapGetters({
      list: "stages/list",
      current: "stages/current",
      formProgress: "form/progress"
    })
  },
  components: {
    StageProgress
  }
};
</script>

<style lang="scss">
.stages {
  $this: &;

  padding: 35px 20px;
  padding-right: 15px;
  background-color: rgba(59, 122, 190, 0.2);
  border-radius: 14px;

  &__list {
  }

  &__item {
    display: flex;
    padding-bottom: 75px;
    position: relative;
    color: $second;
    font-weight: $semibold;
    font-size: 24px;
    line-height: 1.2;
    overflow: hidden;
    transition: ease color 300ms;

    &.is-current {
      color: $white;

      #{$this}__point::before {
        transform: scale(0);
      }

      #{$this}__point::after {
        background-image: linear-gradient(180deg, $second 0%, $accent 100%);
      }
    }

    &.is-current ~ & {
      color: $white;

      #{$this}__point {
        border-color: $accent;
      }

      #{$this}__point::before {
        transform: scale(0);
      }

      #{$this}__point::after {
        background-color: $accent;
        background-image: none;
      }
    }

    &:last-child {
      padding-bottom: 0;

      #{$this}__point::after {
        display: none;
      }
    }
  }

  &__headline {
    padding-top: 5px;
  }

  &__label {
  }

  &__point {
    flex-shrink: 0;
    width: 37px;
    height: 37px;
    margin-right: 12px;
    border-radius: 50%;
    border: 1px solid $second;

    &::before {
      content: "";
      display: block;
      width: 19px;
      height: 19px;
      border-radius: 50%;
      background-color: $second;
      position: absolute;
      top: 9px;
      left: 9px;
      transform: scale(1);
      transition: ease 200ms transform;
    }

    &::after {
      content: "";
      display: block;
      width: 1px;
      height: calc(100% - 37px);
      position: absolute;
      left: 18px;
      top: 37px;
      background-color: $second;
    }
  }

  &__progress {
    margin-top: 2px;
  }
}
</style>
