import Notify from "./notify.js";

/*
 * Alerts for error and success messages
 *
 * 01 Error Alert
 * Call notify message with error styles
 */
export function errorAlert(text, heading = "Возникла ошибка", time = 3000) {
  new Notify({
    type: "is-danger",
    position: "bottom-center",
    heading,
    text,
    time,
    icon: "icon-cancel"
  });
}

/*
 * 02 Success Alert
 * Call notify message with sussess styles
 */
export function successAlert(
  text,
  heading = "Действие выполнено успешно",
  time = 3000
) {
  new Notify({
    type: "is-primary",
    position: "bottom-center",
    heading,
    text,
    time,
    icon: "icon-ok"
  });
}
