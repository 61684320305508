//import issuers from "./_issuers";

export default {
  title:
      "Распоряжение о совершении операции списания/зачисления ценных бумаг (с участием центрального депозитария)",
  activeStep: 0,
  plugins: [
    {
      type: "number-to-word",
      config: {
        field: "stocksQtyDigits",
        target: "stocksQtyWords"
      }
    }
  ],
  data: {
    smsCode: "",
    ticket: "",
    issuer: {
      value: "",
      //options: issuers,
      allowOther: false
    },
    stocksType: {
      options: "Обыкновенные,Привилегированные",
      value: "",
      allowOther: false
    },
    stocksNumber: "",
    stocksQtyDigits: "",
    stocksQtyWords: "",
    restrictions: {
      value: "",
      template: "radio",
      allowOther: false,
      options:
          "Не обременены никакими обязательствами,Обременены обязательствами"
    },
    uniqueIdNumber: "",
    doc_1_title: "",
    doc_1_number: "",
    doc_1_date: "",
    doc_2_title: "",
    doc_2_number: "",
    doc_2_date: "",
    doc_3_title: "",
    doc_3_number: "",
    doc_3_date: "",
    sum: "",
    accountType: {
      options:
          "Владелец,Депозитный,Номинальный держатель,Казначейский,Доверительный управляющий,Нотариус",
      value: "",
      allowOther: false,
      template: "radio"
    },
    account: {
      value: "",
      template: "text",
      options: "",
      allowOther: true
    },
    surname: "",
    name: "",
    patronymic: "",
    citizenship: "",
    birthdate: "",
    birthplace: "",
    passSeries: "",
    passNumber: "",
    passDate: "",
    passIssuer: "",
    passCode: "",
    beneficiarInfo: {
      value: "",
      template: "radio",
      allowOther: false,
      options: [
        "Лицо, передающее ценные бумаги, действует к собственной выгоде",
        "Лицо, передающее ценные бумаги, действует к выгоде иного лица"
      ]
    },
    passScan: {
      value: [],
      type: "ImgField"
    },
    photo: {
      value: [],
      type: "ImgField"
    },
    additionalDocuments: {
      value: [],
      type: "ImgField"
    }
  },
  steps: [
    {
      index: 0,
      header: "1. Эмитент",
      fields: [
        {
          id: "ticket",
          label: "Номер талона",
          legend: "Введите номер талона, который был выдан Вам на стойке регистрации",
          placeholder: "",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "issuer",
          label: "Полное официальное наименование эмитента ценных бумаг",
          placeholder: "Публичное акционерное общество \"Сбербанк России\"",
          width: "full",
          legend:
              "Начните вводить название эмитента, затем выберите подходящий вариант из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          endpoint: "/api/v1/issuers/search",
          showError: ""
        },
        {
          id: "stocksType",
          label: "Вид, категория (тип) ценных бумаг (ЦБ)",
          placeholder: "Например: привилегированные",
          width: "full",
          legend: "Введите вид, категорию ценных бумаг",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "stocksNumber",
          label: "Гос. рег. номер выпуска ЦБ",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "stocksQtyDigits",
          label: "Количество ЦБ цифрами",
          width: "full",
          mask: {
            alias: 'numeric',
            allowMinus: false,
          },
          legend: "Например: 150",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "stocksQtyWords",
          readonly: true,
          label: "Количество ЦБ прописью",
          width: "full",
          legend: "Например: сто пятьдесят",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "restrictions",
          label: "Ограничения (или) обременения",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        }
      ]
    },
    {
      index: 1,
      header: "2. Основания передачи ценных бумаг",
      fields: [
        {
          id: "uniqueIdNumber",
          label:
              "Уникальный идентификационный номер (референс)[*]В идентификаторе могут использоваться только заглавные латинские буквы и цифры[/*]",
          width: "full",
          legend:
              "В идентификаторе могут использоваться только заглавные латинские буквы и цифры",
          mask: {
            regex: "^[A-Z0-9]{1,16}$"
          },
          validate: {
            pattern: {
              reg: "^[A-Z0-9]{1,16}$",
              error:
                  "В идентификаторе могут использоваться только заглавные латинские буквы и цифры"
            },
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "doc_1_title",
          label: "Название",
          width: "full",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "doc_1_number",
          label: "Номер документа",
          width: "one-half",

          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "doc_1_date",
          label: "Дата",
          width: "one-half",
          mask: "d9.m9.y999",
          placeholder: "__.__.____",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            }
          },
          showError: ""
        },

        {
          id: "doc_2_title",
          label: "Название",
          width: "full"
        },
        {
          id: "doc_2_number",
          label: "Номер документа",
          width: "one-half"
        },
        {
          id: "doc_2_date",
          label: "Дата",
          width: "one-half",
          mask: "d9.m9.y999",
          placeholder: "__.__.____",
          validate: {
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            }
          }
        },

        {
          id: "doc_3_title",
          label: "Название",
          width: "full"
        },
        {
          id: "doc_3_number",
          label: "Номер документа",
          width: "one-half"
        },
        {
          id: "doc_3_date",
          label: "Дата",
          width: "one-half",
          mask: "d9.m9.y999",
          placeholder: "__.__.____",
          validate: {
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            }
          }
        },
        // {
        //   id: "sum",
        //   label:
        //     "Сумма сделки[*]Введите сумму сделки, с точностью до копеек (в качестве разделителя используйте точку)[/*]",
        //   width: "one-half",
        //   legend:
        //     "Введите сумму сделки, с точностью до копеек (в качестве разделителя используйте точку)",
        //   placeholder: "Например: 1000.00",
        //   format: "%d руб.",
        //   autoFormat: {
        //     pattern: "^\\d+\\.\\d{2}$",
        //     replacement: "%d.00"
        //   },
        //   validate: {
        //     pattern: {
        //       reg: "^\\d*\\.\\d{2}$",
        //       error:
        //         "Введите сумму сделки в указанном формате (с указанием копеек после точки)"
        //     },
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: ""
        // }
      ]
    },
    {
      index: 2,
      header: "3. Зарегистрированное лицо, передающее ценные бумаги",
      fields: [
        {
          id: "accountType",
          label: "Вид лицевого счета",
          placeholder: "",
          width: "full",
          legend: "Выбериет значение из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "account",
          label: "Номер лицевого счета в реестре",
          width: "full",
          // legend:
          //     "Выберите интересующий счет из выпадающего списка или введите сами",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          showError: false
        },
        {
          id: "surname",
          label: "Фамилия",
          placeholder: "Иванов",
          width: "one-half",
          legend: "Введите фамилию как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "name",
          label: "Имя",
          placeholder: "Иван",
          width: "one-half",
          legend: "Введите имя как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "patronymic",
          label: "Отчество",
          placeholder: "Иванович",
          width: "one-half",
          legend: "Введите отчество как в документе",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          showError: false
        },
        // {
        //   id: "citizenship",
        //   label: "Гражданство",
        //   placeholder: "",
        //   width: "one-half",
        //   legend: "Введите гражданство как в документе",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: false
        // },
        // {
        //   id: "birthdate",
        //   label: "Дата рождения",
        //   placeholder: "__.__.____",
        //   width: "one-half",
        //   mask: "99.99.9999",
        //   legend: "Введите дату рождения как в документе",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: false
        // },
        // {
        //   id: "birthplace",
        //   label:
        //     "Место рождения, (наименование страны рождения и населенного пункта)",
        //   width: "full",
        //   legend: "Введите место рождения как в документе",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: false
        // },
        {
          id: "passSeries",
          label: "Серия паспорта",
          placeholder: "__ __",
          width: "one-half",
          mask: "99 99",
          legend: "Введите серию паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\s\\d{2}",
              error:
                "Серия паспорта состоит из двух чисел, разделенных пробелом (00 00)"
            }
          },
          showError: false
        },
        {
          id: "passNumber",
          label: "Номер паспорта",
          mask: "999 999",
          placeholder: "___ ___",
          width: "one-half",
          legend: "Введите номер паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{3}\\s\\d{3}",
              error:
                "Номер паспорта состоит из двух чисел, разделенных пробелом (000 000)"
            }
          },
          showError: false
        },
        {
          id: "passDate",
          label: "Дата выдачи паспорта",
          placeholder: "",
          width: "one-half",
          legend: "Введите дату выдачи паспорта",
          mask: "d9.m9.y999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\.\\d{2}\\.\\d{4}",
              error: "Дата должна быть введена в формате дд.мм.гггг"
            },
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            },
            custom: ["date"]
          },
          showError: false
        },
        // {
        //   id: "passIssuer",
        //   label:
        //     "Наименование органа, выдавшего документ[*]Наименование органа укажите точно как в паспорте[/*]",
        //   placeholder: "",
        //   legend:
        //     "Введите наименование органа, выдавшего паспорт как в документе",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: ""
        // },
        // {
        //   id: "passCode",
        //   label: "Код подразделения",
        //   placeholder: "",
        //   width: "one-half",
        //   legend: "Введите код подразделения в точности, как в паспорте",
        //   mask: "999-999",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: false
        // },
        // {
        //   id: "beneficiarInfo",
        //   label:
        //     "Сведения о Выгодоприобретателе зарегистрированного лица-владельца ценных бумаг, передающего ценные бумаги[*]Заполняется в случае перехода прав собственности на ценные бумаги[/*]"
        // }
      ]
    }
  ]
};
