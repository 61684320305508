import store from "@/store";

export default (to, from, next) => {
  const id = store.getters["services/current"];
  const formStageCompleted = store.getters["stages/isCompleted"]("form");

  if (id && formStageCompleted) {
    store.dispatch("stages/unsetCompleted", ["pay"]);

    next();
  } else {
    next("/form");
  }
};
