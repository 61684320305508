<template lang="pug">
  .header
    .grid
      .col-3.grid.is-middle
        button.header__back.header__home(
          v-if='isFar'
          @click='goHome'
        ) На главную
        button.header__back(
          v-if='showBackButton'
          @click='goBack'
        ) {{ backLabel }}
      .col-6
        .header__logo
          Logo
      .col-3
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Logo from "@/components/Logo.vue";

export default {
  computed: {
    ...mapGetters({
      currentIndex: "stages/currentIndex",
      stagesAmount: "stages/amount"
    }),
    showBackButton() {
      return !(
        this.currentIndex === 0 || this.currentIndex === this.stagesAmount - 1
      );
    },
    backLabel() {
      let label;
      switch (this.$route.path) {
        case "/form":
          label = "На главную";
          break;
        default:
          label = "Назад";
          break;
      }
      return label;
    },
    isFar() {
      return this.$route.path != "/form" && this.$route.path != "/";
    }
  },
  components: {
    Logo
  },
  methods: {
    ...mapActions({
      goBack: "stages/goBack",
      goHome: "stages/goHome"
    }),
  }
};
</script>

<style lang="scss">
.header {
  background-image: rgba(59, 123, 190, 0.2);
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 30px 30px;

  &__back {
    display: flex;
    align-items: center;
    margin-left: 30px;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $white;
    font-size: 24px;
    line-height: 29px;
    cursor: pointer;
    transition: ease color 200ms;

    &:hover {
      color: $second;
    }

    &::before {
      content: "";
      display: block;
      width: 12px;
      height: 20px;
      margin-right: 16px;
      background-image: url(../img/arrow-back.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }

  &__home {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 8px;
      display: block;
      width: 12px;
      height: 20px;
      margin-right: 16px;
      background-image: url(../img/arrow-back.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin: 25px auto 15px;
  }
}
</style>
