//import issuers from "./_issuers";

export default {
  title: "Распоряжение на предоставление информации",
  activeStep: 0,
  plugins: [
    {
      type: "number-to-word",
      config: [
        {
          field: "countExcerptAccountCurrent",
          target: "countExcerptAccountCurrentWords"
        },
        {
          field: "countExcerptAccountCurrentAC",
          target: "countExcerptAccountCurrentACWords"
        },
        {
          field: "countExcerptAccountCurrentCountAC",
          target: "countExcerptAccountCurrentCountACWords"
        },
        {
          field: "countExcerptAccountDate",
          target: "countExcerptAccountDateWords"
        },
        {
          field: "countExcerptOperationsPeriod",
          target: "countExcerptOperationsPeriodWords"
        },
        {
          field: "countNotifyNextOperation",
          target: "countNotifyNextOperationWords"
        },
        {
          field: "countExcerptStocks",
          target: "countExcerptStocksWords"
        },
        {
          field: "countReferenceOther",
          target: "countReferenceOtherWords"
        }
      ]
    }
  ],
  data: {
    smsCode: "",
    ticket: "",
    issuer: {
      value: "",
      //options: issuers,
      template: "text"
    },
    account: "",
    accountType: {
      value: "",
      options:
          "Владелец, Номинальный держатель, Доверительный управляющий, Депозитный, Казначейский",
      template: "radio",
      allowOther: false
    },
    name: "",
    surname: "",
    patronymic: "",
    document: "Паспорт",
    passSeries: "",
    passNumber: "",
    passDate: "",
    birthdate: "",
    countExcerptAccountCurrent: "",
    countExcerptAccountCurrentWords: "",
    countExcerptAccountCurrentAC: "",
    countExcerptAccountCurrentACWords: "",
    countExcerptAccountCurrentCountAC: "",
    countExcerptAccountCurrentCountACWords: "",
    countExcerptAccountDate: "",
    countExcerptAccountDateWords: "",
    countExcerptOperationsPeriod: "",
    countExcerptOperationsPeriodWords: "",
    countExcerptStocks: "",
    countExcerptStocksWords: "",
    countReferenceOther: "",
    countReferenceOtherWords: "",
    countNotifyNextOperation: "",
    countNotifyNextOperationWords: "",
    inputReferenceOther: "",
    excerptAccountCurrentAC: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    excerptAccountCurrentCountAC: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    excerptAccountCurrent: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    excerptAccountDate: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    referenceOther: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    accountDate: "",
    excerptOperationsPeriod: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    operationsPeriodFrom: "",
    operationsPeriodTill: "",
    notifyNextOperation: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    operationType: "",
    operationDocuments: "",
    excerptStocks: {
      type: "CheckboxField",
      template: "checkbox",
      value: false
    },
    stocksType: {
      options:
          "Обыкновенные,Привилегированные",
      allowOther: false,
      value: ""
    },
    stocksNumber: "",
    stockQty: "",
    notification: {
      template: "radio",
      options:
          "Лично в месте подачи документов, Заказным письмом (Отправка документов заказным письмом осуществляется при условии оплаты Услуги в соответствии с Тарифами/прейскурантом регистратора)",
      allowOther: false,
      value: ""
    },
    passScan: {
      value: [],
      type: "ImgField"
    },
    photo: {
      value: [],
      type: "ImgField"
    },
    additionalDocuments: {
      value: [],
      type: "ImgField"
    }
  },
  steps: [
    {
      index: 0,
      header: "1. Эмитент",
      fields: [
        {
          id: "ticket",
          label: "Номер талона",
          legend: "Введите номер талона, который был выдан Вам на стойке регистрации",
          placeholder: "",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: ""
        },
        {
          id: "issuer",
          label: "Полное официальное наименование эмитента ценных бумаг",
          placeholder: "Публичное акционерное общество \"Сбербанк России\"",
          width: "full",
          legend:
            "Начните вводить название эмитента, затем выберите подходящий вариант из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          endpoint: "/api/v1/issuers/search",
          showError: false
        }
      ]
    },
    {
      index: 1,
      header: "2. Зарегистрированное лицо",
      fields: [
        {
          id: "account",
          label: "Номер лицевого счета в реестре",
          width: "full",
          // legend: "Выберите интересующий счет из выпадающего списка",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          showError: false
        },
        {
          id: "accountType",
          label: "Вид лицевого счета",
          placeholder: "",
          width: "full",
          legend: "Выбериет значение из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "surname",
          label: "Фамилия",
          placeholder: "Иванов",
          width: "one-half",
          legend: "Введите фамилию как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "name",
          label: "Имя",
          placeholder: "Иван",
          width: "one-half",
          legend: "Введите имя как в документе",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        },
        {
          id: "patronymic",
          label: "Отчество",
          placeholder: "Иванович",
          width: "one-half",
          legend: "Введите отчество как в документе",
          // validate: {
          //   required: {
          //     error: "Данное поле обязательно для заполнения"
          //   }
          // },
          showError: false
        },
        // {
        //   id: "birthdate",
        //   label: "Дата рождения",
        //   placeholder: "__.__.____",
        //   width: "one-half",
        //   mask: "99.99.9999",
        //   legend: "Введите дату рождения как в документе",
        //   validate: {
        //     required: {
        //       error: "Данное поле обязательно для заполнения"
        //     }
        //   },
        //   showError: false
        // },
        {
          id: "passSeries",
          label: "Серия паспорта",
          placeholder: "__ __",
          width: "one-half",
          mask: "99 99",
          legend: "Введите серию паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\s\\d{2}",
              error:
                "Серия паспорта состоит из двух чисел, разделенных пробелом (00 00)"
            }
          },
          showError: false
        },
        {
          id: "passNumber",
          label: "Номер паспорта",
          mask: "999 999",
          placeholder: "___ ___",
          width: "one-half",
          legend: "Введите номер паспорта",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{3}\\s\\d{3}",
              error:
                "Номер паспорта состоит из двух чисел, разделенных пробелом (000 000)"
            }
          },
          showError: false
        },
        {
          id: "passDate",
          label: "Дата выдачи паспорта",
          placeholder: "",
          width: "one-half",
          legend: "Введите дату выдачи паспорта",
          mask: "d9.m9.y999",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            },
            pattern: {
              reg: "\\d{2}\\.\\d{2}\\.\\d{4}",
              error: "Дата должна быть введена в формате дд.мм.гггг"
            },
            beforeTomorrow: {
              error: "Дата не может быть позднее сегодняшнего дня"
            },
            custom: "date"
          },
          showError: false
        },
      ]
    },
    {
      index: 2,
      header:
          "3. Зарегистрированное лицо просит предоставить следующую информацию",
      validate: {
        required: {
          "error": "Нужно выбрать хотя бы 1 справку",
          fields: [
            "excerptAccountCurrent",
            "excerptOperationsPeriod",
            "excerptAccountDate",
            "notifyNextOperation",
            "excerptStocks",
            "excerptAccountCurrentAC",
            "excerptAccountCurrentCountAC",
            "referenceOther"
          ]
        }
      },
      fields: [
        {
          id: "excerptAccountCurrent",
          placeholder: "Выписка из реестра о текущем состоянии счета",
          width: "one-half",
          mutateFields: [
            {
              id: "countExcerptAccountCurrent",
              value: "",
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                },
              }
            },
            {
              id: "countExcerptAccountCurrentWords",
              value: "",
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                },
              }
            }
          ]
        },
        {
          id: "countExcerptAccountCurrent",
          label: "Количество экз. (1-5) шт.",
          width: "full",
          visible: false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptAccountCurrentWords",
          readonly: true,
          "visible": false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "excerptAccountCurrentAC",
          placeholder: "Выписку из реестра о текущем состоянии счета с указанием доли к УК",
          width: "one-half",
          mutateFields: [
            {
              id: "countExcerptAccountCurrentAC",
              value: false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              id: "countExcerptAccountCurrentACWords",
              value: false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "countExcerptAccountCurrentAC",
          label: "Количество экз. (1-5) шт.",
          width: "full",
          visible: false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptAccountCurrentACWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "excerptAccountCurrentCountAC",
          placeholder: "Выписку из реестра о текущем состоянии счета с указанием доли от количества размещенных ЦБ",
          width: "one-half",
          mutateFields: [
            {
              id: "countExcerptAccountCurrentCountAC",
              value: false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              id: "countExcerptAccountCurrentCountACWords",
              value: false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "countExcerptAccountCurrentCountAC",
          label: "Количество экз. (1-5) шт.",
          width: "full",
          visible: false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptAccountCurrentCountACWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "excerptAccountDate",
          placeholder: "Выписка из реестра о состоянии счета",
          width: "one-half",
          mutateFields: [
            {
              id: "accountDate",
              value: "",
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              id: "countExcerptAccountDate",
              value: "",
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              id: "countExcerptAccountDateWords",
              value: "",
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "countExcerptAccountDate",
          label: "Количество экз. (1-5) шт.",
          width: "full",
          visible: false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptAccountDateWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "accountDate",
          label: "на указанную дату",
          placeholder: "__.__.____",
          mask: "d9.m9.y999",
          width: "one-half",
          visible: false
        },
        {
          "id": "excerptOperationsPeriod",
          "placeholder": "Справка об операциях по счету за период",
          "mutateFields": [
            {
              "id": "operationsPeriodFrom",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "operationsPeriodTill",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countExcerptOperationsPeriod",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countExcerptOperationsPeriodWords",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "operationsPeriodFrom",
          label: "c",
          mask: "d9.m9.y999",
          width: "one-half",
          "placeholder": "__.__.____",
          "visible": false
        },
        {
          id: "operationsPeriodTill",
          label: "по",
          mask: "d9.m9.y999",
          width: "one-half",
          "placeholder": "__.__.____",
          "visible": false
        },
        {
          "id": "countExcerptOperationsPeriod",
          "label": "Количество экз. (1-5) шт.",
          "width": "full",
          "visible": false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptOperationsPeriodWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          "id": "notifyNextOperation",
          "placeholder": "Уведомление о совершении следующей операции",
          "width": "one-half",
          "mutateFields": [
            {
              "id": "operationType",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "operationDocuments",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countNotifyNextOperation",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countNotifyNextOperationWords",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "operationType",
          label: "Тип операции",
          width: "one-half",
          "visible": false
        },
        {
          id: "operationDocuments",
          label: "Документы, на основании которых совершена операция",
          "visible": false
        },
        {
          "id": "countNotifyNextOperation",
          "label": "Количество экз. (1-5) шт.",
          "width": "full",
          "visible": false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countNotifyNextOperationWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          "id": "excerptStocks",
          "placeholder": "Справка из реестра о наличии на лицевом счете определённого количества ЦБ",
          "mutateFields": [
            {
              "id": "stocksType",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "stocksNumber",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countExcerptStocks",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countExcerptStocksWords",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "stockQty",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          id: "stocksType",
          label: "Вид, категория (тип) ценных бумаг",
          width: "one-half",
          "visible": false
        },
        {
          id: "stocksNumber",
          label: "Гос. рег. номер выпуска ценных бумаг",
          width: "one-half",
          "visible": false
        },
        {
          "id": "countExcerptStocks",
          "label": "Количество экз. (1-5) шт.",
          "width": "full",
          "visible": false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countExcerptStocksWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "stockQty",
          label: "Количество ценных бумаг (цифрами и прописью) шт.",
          "visible": false
        },
        {
          "id": "referenceOther",
          "placeholder": "Иная справка",
          "mutateFields": [
            {
              "id": "inputReferenceOther",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countReferenceOther",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            },
            {
              "id": "countReferenceOtherWords",
              "value": false,
              validate: {
                required: {
                  error: "Данное поле обязательно для заполнения"
                }
              }
            }
          ]
        },
        {
          "id": "inputReferenceOther",
          "label": "Название иной справки",
          "width": "full",
          "visible": false
        },
        {
          "id": "countReferenceOther",
          "label": "Количество экз. (1-5) шт.",
          "width": "full",
          "visible": false,
          mask: { regex: "[1-5]" }
        },
        {
          id: "countReferenceOtherWords",
          readonly: true,
          visible: false,
          label: "Количество экз. прописью",
          width: "full"
        },
        {
          id: "notification",
          label: "Способ получения запрашиваемой информации",
          legend: "Выберите значение из выпадающего списка",
          validate: {
            required: {
              error: "Данное поле обязательно для заполнения"
            }
          },
          showError: false
        }
      ]
    }
  ]
};
