import router from "@/router";

export default {
    namespaced: true,
    getters: {
        progress(state) {
            return (state.currentIndex / (state.list.length - 1)) * 100;
        },
        current(state) {
            return state.current;
        },
        list(state) {
            return state.list;
        },
        amount(state) {
            return state.list.length - 1;
        },
        currentIndex(state) {
            return state.currentIndex;
        },
        itemByIndex(state) {
            return index => {
                return state.list[index];
            };
        },
        isCompleted(state) {
            return name => {
                return state.completed[name];
            };
        }
    },
    mutations: {
        setFreeState(state) {
            state.list[3] = {name: "pay", label: "Завершение операции"};
        },
        setPriceState(state) {
            state.list[3] = {name: "pay", label: "Оплата"};
        },
        setCurrent(state, {name, index}) {
            state.current = name;
            state.currentIndex = index;
        },
        setCompleted(state, name) {
            if (name in state.completed) {
                state.completed[name] = true;
            }
        },
        unsetCompleted(state, names = []) {
            names.forEach(name => {
                if (name in state.completed) {
                    state.completed[name] = false;
                }
            });
        }
    },
    actions: {
        setCompleted({commit}, name) {
            commit("setCompleted", name);
        },
        unsetCompleted({commit}, names) {
            commit("unsetCompleted", names);
        },
        setCurrent({commit, state}, name) {
            const index = state.list.findIndex(
                item => item.name.toLowerCase() === name.toLowerCase()
            );

            commit("setCurrent", {name, index});
        },
        goBack({getters}) {
            if (getters.currentIndex < 1) return false;

            const index = getters.currentIndex - 1;
            const stage = getters.itemByIndex(index);

            router.push(stage.name);
        },
        goHome({getters}) {
            const stage = getters.itemByIndex(0);

            router.push(stage.name);
        }
    },
    state: {
        completed: {
            form: false,
            varification: false,
            pay: false
        },
        list: [
            {name: "services", label: "Выбор услуги"},
            {name: "form", label: "Заполнение формы"},
            {name: "varification", label: "Проверка данных"},
            {name: "pay", label: "Оплата"},
            {name: "success", label: "Готово"}
        ],
        current: "start",
        currentIndex: 0
    }
};
