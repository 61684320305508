import Vue from "vue";
import App from "./App.vue";
import Icon from "@/components/Icon.vue";
import Loader from "@/components/Loader.vue";
import store from "@/store";
import router from "@/router";

import "@/styles/app.scss";

Vue.config.productionTip = false;
Vue.component("Icon", Icon);
Vue.component("Loader", Loader);

router.afterEach(to => {
  document.title = to.meta.title || document.title;

  const routeName = router.currentRoute.name;

  if (routeName) {
    store.dispatch("stages/setCurrent", routeName);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
