import Vue from "vue";
import Vuex from "vuex";

import user from "./user";
import services from "./services";
import stages from "./stages";
import modal from "./modal";
import form from "./form";
import document from "./document";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    services,
    stages,
    modal,
    form,
    document,
  }
});
