<template lang="pug">
  .modal
    .modal__overlay(
      @click='close'
    )
      .modal__box
        .modal__content(
          v-html='content'
        )
    button.modal__close(
      type='button'
      @click='close'
    )
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    content: String
  },
  methods: {
    ...mapActions({
      setModalContent: "modal/set"
    }),
    close() {
      this.setModalContent(null);
    }
  }
};
</script>

<style lang="scss">
.modal {
  $this: &;

  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: $layer-1;

  &.is-captcha {
    #{$this}__box {
      display: flex;
      min-height: 100vh;
    }

    #{$this}__content {
      max-width: 420px;
      border-radius: 12px;
    }
  }

  &__close {
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
    border: none;
    background-image: url(../img/close.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;
  }

  &__overlay {
    overflow: auto;
    background-color: rgba(52, 73, 96, 0.9);
  }

  &__box {
    min-height: 100vh;
    padding: 67px;
    pointer-events: none;
  }

  &__content {
    width: 100%;
    max-width: 1000px;
    min-height: 300px;
    margin: auto;
    background-color: $white;
    color: $black;
    pointer-events: all;

    embed {
      width: 100%;
      min-height: calc(100vh - 134px);
    }
  }
}
</style>
