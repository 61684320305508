import store from "@/store";

export default (to, from, next) => {
  const id = store.getters["services/current"];
  const formStageCompleted = store.getters["stages/isCompleted"]("form");
  const varificationStageCompleted = store.getters["stages/isCompleted"](
    "varification"
  );

  if (id && formStageCompleted && varificationStageCompleted) {
    next();
  } else {
    next("/varification");
  }
};
