<template lang="pug">
  DynamicModal(
    :okBtn='false',
    :open='true',
    size="xs"
  )
    .inactivity-alert
      .inactivity-alert__header Внимание
      .inactivity-alert__body 
        p Уважаемый пользователь, мы заметили, что Вы уже в течение 3 минут не взаимодействуете с программой. 
        p В целях обеспечения конфиденциальности Вашей личной информации, все введенные в форму данные будут сброшены через 30 секунд.
        p Если Вы хотите продолжить работу, нажмитне на кнопку "Продолжить".
        .inactivity-alert__warning Сброс данных через 
        .inactivity-alert__timer {{ time }} секунд
      .inactivity-alert__footer
        Button(
          label='Продолжить работу'
          :action='continueWork'
        )
        

</template>

<script>
import Button from "@/components/Button.vue";
import DynamicModal from "@/components/DynamicModal.vue";

import { mapActions } from "vuex";

export default {
  data() {
    return {
      time: 30,
      timer: false,
    }
  },
  components: {
    Button,
    DynamicModal
  },
  methods: {
    ...mapActions({
      setActive: "user/setActive"
    }),
    continueWork() {
      this.reset();
      this.setActive();
    },
    reset() {
      this.time = 30;
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.time > 1) {
        this.time--;
      } else {
        this.reset();
        this.setActive();
        this.$router.push('/');
      }
    }, 1000);
  }
};
</script>

<style lang="scss">
.inactivity-alert {
  &__header {
    font-size: 24px;
    margin-bottom: 15px;
  }

  &__warning {
    font-size: 22px;
    //margin-bottom: 15px;
    margin-top: 35px;
    text-align: center;
  }

  &__timer {
    font-size: 34px;
    text-align: center;
    margin-bottom: 35px;
    color: rgb(155, 8, 8);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    button + button {
      margin-left: 15px;
    }
  }
  p {
    margin-bottom: 10px;
  }
}
</style>
