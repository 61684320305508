export default {
  date(value) {
    console.log(value);
    const [day, month, year] = value.split(".");
    const currentYear = new Date().getFullYear();
    const minYear = 1900;

    switch (true) {
      case isNaN(Number(day)):
      case isNaN(Number(month)):
      case isNaN(Number(year)):
      case Number(day) > 31:
      case Number(month) > 12:
      case Number(year) > currentYear || year <= minYear: {
        return { valid: false, message: "Неверная дата" };
      }
    }

    return { valid: true, message: "" };
  }
};
