import $ from "jquery";

export default function notify(obj) {
  this.template = `
    <div class="notify__message %type%">
      <h3 class="notify__title">%heading%</h3>
      <p class="notify__text">%text%</p>
      <button class="notify__close"></button>
    </div>
  `;

  this.hide = function() {
    $(this).fadeOut(200, function() {
      $(this).remove();
    });
  };

  this.afterAppend = function(el) {
    const $el = $(el);

    $el.find(".notify__close").on("click", this.hide.bind(el));

    // setTimeout
    if (el.time == -1) {
      return false;
    } else {
      el.timer = setTimeout(this.hide.bind(el), el.time);

      $el.on("mouseenter", function() {
        clearTimeout(el.timer);
      });

      $el.on("mouseleave", this, function(e) {
        el.timer = setTimeout(e.data.hide.bind(el), el.time);
      });
    }
  };

  this.getWrapper = function({ position }) {
    const $wrapper = $(`.notify.${position}`);

    if ($wrapper.length > 0) {
      return $wrapper;
    } else {
      const $newWrapper = $(`<div class='notify ${position}'>`);
      const $body = $("body");

      $body.append($newWrapper);

      return $newWrapper;
    }
  };

  this.render = function(obj) {
    let message = this.template;

    for (let k in obj) {
      message = message.replace("%" + k + "%", obj[k]);
    }

    const $el = $("<div>");

    $el.html(message).hide();
    $el.time = obj.time || 1000;

    this.getWrapper(obj).append($el);
    this.afterAppend($el);

    $el.fadeIn(200);
  };

  this.render(obj);
}
