export const debounce = (fn, ms = 0) => {
  let timer = null;

  return (args) => {
      if (timer) {
          clearTimeout(timer);
      }

      timer = setTimeout(() => {
          fn(args);
      }, ms);
  };
};
